var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.cveList
    ? _c(
        "div",
        { staticClass: "cve-wrapper" },
        _vm._l(_vm.cveList, function (cve) {
          return _c("div", { key: cve.id, staticClass: "cve-row" }, [
            _c(
              "a",
              {
                staticClass: "upper",
                attrs: { href: cve.url, target: "_blank" },
              },
              [
                _c("p", { class: `score ${_vm.makeScoreColor(cve.score)}` }, [
                  _vm._v(_vm._s(cve.score)),
                ]),
                _c("div", { staticClass: "title-wrap" }, [
                  _c("p", { staticClass: "title" }, [_vm._v(_vm._s(cve.id))]),
                  _c("span", { staticClass: "date" }, [
                    _vm._v(_vm._s(_vm._f("formatDate")(cve.publishDate))),
                  ]),
                  _c("span", { staticClass: "last-updated" }, [
                    _vm._v(
                      "Last Updated: " +
                        _vm._s(_vm._f("formatDate")(cve.updateDate))
                    ),
                  ]),
                  _c(
                    "span",
                    {
                      class: `exploit-count ${_vm.makeExploitColor(
                        cve.numExploits
                      )}`,
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatExploitCount")(cve.numExploits)
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _c("p", { staticClass: "cve-description" }, [
              _vm._v(
                " " + _vm._s(_vm._f("formatDescription")(cve.description)) + " "
              ),
              cve.description.length > 350
                ? _c(
                    "a",
                    {
                      staticClass: "read-more",
                      attrs: { href: cve.url, target: "_blank" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("widgets.general.open-link")) + " "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }